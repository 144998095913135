import { useState } from 'react';

export function useWhiteList() {
  const [allowedOrigins, setAllowedOrigins] = useState([]);

  return {
    allowedOrigins,
    isFetchAllowedOriginsPending: false,
  };
}
